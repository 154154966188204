import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import router from "./router/index";

Vue.config.productionTip = false

Vue.use(VueRouter);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

document.body.style.fontSize = "14px"; //设置页面根元素字体大小

const setRem = () => {
  let vw = document.documentElement.clientWidth; //得出当前视窗宽度
  document.documentElement.style.fontSize = vw / 7.5 + "px"; //根据视窗宽度换算出跟字体大小 移动端设备vw/7.5 电脑端设备 vw/19.2
};
setRem();

//监听窗口宽度变化
window.addEventListener("resize", function() {
  setRem();
});
