import Vue from "vue";
import Router from "vue-router";
// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;

// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

Vue.use(Router);
/**
 * phone 手机入门模块文件夹
 * phoneIntroduction 手机入门首页
 * phoneOnLine 手机如何联网
 * */
const routes = [
  {
    path: "/",
    name: "homeIndex", // 首页
    component: () => import("@/views/homeIndex"),
  },
  {
    path: "/alltype",
    name: "listIndex", // 综合入口首页
    component: () => import("@/views/listIndex"),
  },
  {
    path: "/success",
    name: "practiceSuccess", // 综合入口首页
    component: () => import("@/views/practiceSuccess"),
  },
  {
    path: "/alltype/phone",
    name: "phoneIntroduction", // 手机入门入口
    component: () => import("@/views/phone/phoneIntroduction"),
  },
  {
    path: "/alltype/phone/phoneonline", // 手机如何联网
    name: "phoneOnLine",
    component: () => import("@/views/phone/phoneOnLine"),
  },
  {
    path: "/alltype/phone/phoneonline/onlineAndroid1", // 手机如何联网
    name: "onlineAndroid1",
    component: () => import("@/views/phone/online/onlineAndroid1"),
  },
  {
    path: "/alltype/phone/phoneonline/onlineAndroid2", // 手机如何联网
    name: "onlineAndroid2",
    component: () => import("@/views/phone/online/onlineAndroid2"),
  },
  {
    path: "/alltype/phone/phoneonline/onlineAndroid3", // 手机如何联网
    name: "onlineAndroid3",
    component: () => import("@/views/phone/online/onlineAndroid3"),
  },
  {
    path: "/alltype/phone/phoneonline/onlineAndroid4", // 手机如何联网
    name: "onlineAndroid4",
    component: () => import("@/views/phone/online/onlineAndroid4"),
  },
  {
    path: "/alltype/phone/phoneonline/onlineAndroid5", // 手机如何联网
    name: "onlineAndroid5",
    component: () => import("@/views/phone/online/onlineAndroid5"),
  },
  {
    path: "/alltype/phone/phoneonline/onlineIos1", // 手机如何联网
    name: "onlineIos1",
    component: () => import("@/views/phone/online/onlineIos1"),
  },
  {
    path: "/alltype/phone/phoneonline/onlineIos2", // 手机如何联网
    name: "onlineIos2",
    component: () => import("@/views/phone/online/onlineIos2"),
  },
  {
    path: "/alltype/phone/phoneonline/onlineIos3", // 手机如何联网
    name: "onlineIos3",
    component: () => import("@/views/phone/online/onlineIos3"),
  },
  {
    path: "/alltype/phone/phoneonline/onlineIos4", // 手机如何联网
    name: "onlineIos4",
    component: () => import("@/views/phone/online/onlineIos4"),
  },
  {
    path: "/alltype/phone/phoneonline/onlineIos5", // 手机如何联网
    name: "onlineIos5",
    component: () => import("@/views/phone/online/onlineIos5"),
  },
  {
    path: "/alltype/phone/phonesetting", // 手机如何设置更安全
    name: "phoneSetting",
    component: () => import("@/views/phone/phoneSetting"),
  },
  {
    path: "/alltype/phone/phonesetting/settingAndroid1", // 手机如何设置更安全
    name: "settingAndroid1",
    component: () => import("@/views/phone/setting/settingAndroid1"),
  },
  {
    path: "/alltype/phone/phonesetting/settingAndroid2", // 手机如何设置更安全
    name: "settingAndroid2",
    component: () => import("@/views/phone/setting/settingAndroid2"),
  },
  {
    path: "/alltype/phone/phonesetting/settingAndroid3", // 手机如何设置更安全
    name: "settingAndroid3",
    component: () => import("@/views/phone/setting/settingAndroid3"),
  },
  {
    path: "/alltype/phone/phonesetting/settingAndroid4", // 手机如何设置更安全
    name: "settingAndroid4",
    component: () => import("@/views/phone/setting/settingAndroid4"),
  },
  {
    path: "/alltype/phone/phonesetting/settingAndroid5", // 手机如何设置更安全
    name: "settingAndroid5",
    component: () => import("@/views/phone/setting/settingAndroid5"),
  },
  {
    path: "/alltype/phone/phonesetting/settingAndroid6", // 手机如何设置更安全
    name: "settingAndroid6",
    component: () => import("@/views/phone/setting/settingAndroid6"),
  },
  {
    path: "/alltype/phone/phonesetting/settingAndroid7", // 手机如何设置更安全
    name: "settingAndroid7",
    component: () => import("@/views/phone/setting/settingAndroid7"),
  },
  {
    path: "/alltype/phone/phonesetting/settingAndroid8", // 手机如何设置更安全
    name: "settingAndroid8",
    component: () => import("@/views/phone/setting/settingAndroid8"),
  },
  {
    path: "/alltype/phone/phoneoldmodel", // 开启手机"老年模式
    name: "phoneOldmodel",
    component: () => import("@/views/phone/phoneOldmodel"),
  },
  {
    path: "/alltype/phone/phoneoldmodel/oldmodelAndroid1", // 开启手机"老年模式
    name: "oldmodelAndroid1",
    component: () => import("@/views/phone/oldmodel/oldmodelAndroid1"),
  },
  {
    path: "/alltype/phone/phoneoldmodel/oldmodelAndroid2", // 开启手机"老年模式
    name: "oldmodelAndroid2",
    component: () => import("@/views/phone/oldmodel/oldmodelAndroid2"),
  },
  {
    path: "/alltype/phone/phoneoldmodel/oldmodelAndroid3", // 开启手机"老年模式
    name: "oldmodelAndroid3",
    component: () => import("@/views/phone/oldmodel/oldmodelAndroid3"),
  },
  {
    path: "/alltype/phone/phoneoldmodel/oldmodelAndroid4", // 开启手机"老年模式
    name: "oldmodelAndroid4",
    component: () => import("@/views/phone/oldmodel/oldmodelAndroid4"),
  },
  {
    path: "/alltype/phone/phoneoldmodel/oldmodelAndroid5", // 开启手机"老年模式
    name: "oldmodelAndroid5",
    component: () => import("@/views/phone/oldmodel/oldmodelAndroid5"),
  },
  {
    path: "/alltype/phone/phoneoldmodel/oldmodelAndroid6", // 开启手机"老年模式
    name: "oldmodelAndroid6",
    component: () => import("@/views/phone/oldmodel/oldmodelAndroid6"),
  },
  {
    path: "/alltype/phone/phonedownload", // 手机APP软件的下载安装
    name: "phoneDownload",
    component: () => import("@/views/phone/phoneDownload"),
  },
  {
    path: "/alltype/phone/phonedownload/diAndroid1", // 手机APP软件的下载安装
    name: "diAndroid1",
    component: () => import("@/views/phone/downloadandinstall/diAndroid1"),
  },
  {
    path: "/alltype/phone/phonedownload/diAndroid2", // 手机APP软件的下载安装
    name: "diAndroid2",
    component: () => import("@/views/phone/downloadandinstall/diAndroid2"),
  },
  {
    path: "/alltype/phone/phonedownload/diAndroid3", // 手机APP软件的下载安装
    name: "diAndroid3",
    component: () => import("@/views/phone/downloadandinstall/diAndroid3"),
  },
  {
    path: "/alltype/phone/phonedownload/diAndroid4", // 手机APP软件的下载安装
    name: "diAndroid4",
    component: () => import("@/views/phone/downloadandinstall/diAndroid4"),
  },
  {
    path: "/alltype/phone/phonedownload/diAndroid5", // 手机APP软件的下载安装
    name: "diAndroid5",
    component: () => import("@/views/phone/downloadandinstall/diAndroid5"),
  },
  {
    path: "/alltype/phone/phonedownload/diIos1", // 手机APP软件的下载安装
    name: "diIos1",
    component: () => import("@/views/phone/downloadandinstall/diIos1"),
  },
  {
    path: "/alltype/phone/phonedownload/diIos2", // 手机APP软件的下载安装
    name: "diIos2",
    component: () => import("@/views/phone/downloadandinstall/diIos2"),
  },
  {
    path: "/alltype/phone/phonedownload/diIos3", // 手机APP软件的下载安装
    name: "diIos3",
    component: () => import("@/views/phone/downloadandinstall/diIos3"),
  },
  {
    path: "/alltype/phone/phonedownload/diIos4", // 手机APP软件的下载安装
    name: "diIos4",
    component: () => import("@/views/phone/downloadandinstall/diIos4"),
  },
  {
    path: "/alltype/phone/phonedownload/diIos5", // 手机APP软件的下载安装
    name: "diIos5",
    component: () => import("@/views/phone/downloadandinstall/diIos5"),
  },
  {
    path: "/alltype/phone/phonedownload/diIos6", // 手机APP软件的下载安装
    name: "diIos6",
    component: () => import("@/views/phone/downloadandinstall/diIos6"),
  },
  {
    path: "/alltype/wechat", // 微信
    name: "wechatIndex",
    component: () => import("@/views/wechat/wechatIndex"),
  },
  {
    path: "/alltype/wechat/step1", // 如何添加微信好友
    name: "step1Index",
    component: () => import("@/views/wechat/step1Index"),
  },
  {
    path: "/alltype/wechat/step1/step1", // 如何添加微信好友
    name: "wechatStep11",
    component: () => import("@/views/wechat/step1/wechatStep1"),
  },
  {
    path: "/alltype/wechat/step1/step2", // 如何添加微信好友
    name: "wechatStep12",
    component: () => import("@/views/wechat/step1/wechatStep2"),
  },
  {
    path: "/alltype/wechat/step1/step3", // 如何添加微信好友
    name: "wechatStep13",
    component: () => import("@/views/wechat/step1/wechatStep3"),
  },
  {
    path: "/alltype/wechat/step1/step4", // 如何添加微信好友
    name: "wechatStep14",
    component: () => import("@/views/wechat/step1/wechatStep4"),
  },
  {
    path: "/alltype/wechat/step1/step5", // 如何添加微信好友
    name: "wechatStep15",
    component: () => import("@/views/wechat/step1/wechatStep5"),
  },
  {
    path: "/alltype/wechat/step1/step6", // 如何添加微信好友
    name: "wechatStep16",
    component: () => import("@/views/wechat/step1/wechatStep6"),
  },
  {
    path: "/alltype/wechat/step1/step7", // 如何添加微信好友
    name: "wechatStep17",
    component: () => import("@/views/wechat/step1/wechatStep7"),
  },
  {
    path: "/alltype/wechat/step1/step8", // 如何添加微信好友
    name: "wechatStep18",
    component: () => import("@/views/wechat/step1/wechatStep8"),
  },
  {
    path: "/alltype/wechat/step1/step9", // 如何添加微信好友
    name: "wechatStep19",
    component: () => import("@/views/wechat/step1/wechatStep9"),
  },
  {
    path: "/alltype/wechat/step2", // 发起微信聊天
    name: "step2Index",
    component: () => import("@/views/wechat/step2Index"),
  },
  {
    path: "/alltype/wechat/step2/step1", // 发起微信聊天
    name: "wechatStep21",
    component: () => import("@/views/wechat/step2/wechatStep1"),
  },
  {
    path: "/alltype/wechat/step2/step2", // 发起微信聊天
    name: "wechatStep22",
    component: () => import("@/views/wechat/step2/wechatStep2"),
  },
  {
    path: "/alltype/wechat/step2/step3", // 发起微信聊天
    name: "wechatStep23",
    component: () => import("@/views/wechat/step2/wechatStep3"),
  },
  {
    path: "/alltype/wechat/step2/step4", // 发起微信聊天
    name: "wechatStep24",
    component: () => import("@/views/wechat/step2/wechatStep4"),
  },
  {
    path: "/alltype/wechat/step2/step5", // 发起微信聊天
    name: "wechatStep25",
    component: () => import("@/views/wechat/step2/wechatStep5"),
  },
  {
    path: "/alltype/wechat/step2/step6", // 发起微信聊天
    name: "wechatStep26",
    component: () => import("@/views/wechat/step2/wechatStep6"),
  },
  {
    path: "/alltype/wechat/step2/step7", // 发起微信聊天
    name: "wechatStep27",
    component: () => import("@/views/wechat/step2/wechatStep7"),
  },
  {
    path: "/alltype/wechat/step2/step8", // 发起微信聊天
    name: "wechatStep28",
    component: () => import("@/views/wechat/step2/wechatStep8"),
  },
  {
    path: "/alltype/wechat/step2/step9", // 发起微信聊天
    name: "wechatStep29",
    component: () => import("@/views/wechat/step2/wechatStep9"),
  },
  {
    path: "/alltype/wechat/step2/step10", // 发起微信聊天
    name: "wechatStep210",
    component: () => import("@/views/wechat/step2/wechatStep10"),
  },
  {
    path: "/alltype/wechat/step2/step11", // 发起微信聊天
    name: "wechatStep211",
    component: () => import("@/views/wechat/step2/wechatStep11"),
  },
  {
    path: "/alltype/wechat/step3", // 和朋友语音视频通话
    name: "step3Index",
    component: () => import("@/views/wechat/step3Index"),
  },
  {
    path: "/alltype/wechat/step3/step1", // 和朋友语音视频通话
    name: "wechatStep31",
    component: () => import("@/views/wechat/step3/wechatStep1"),
  },
  {
    path: "/alltype/wechat/step3/step2", // 和朋友语音视频通话
    name: "wechatStep32",
    component: () => import("@/views/wechat/step3/wechatStep2"),
  },
  {
    path: "/alltype/wechat/step3/step3", // 和朋友语音视频通话
    name: "wechatStep33",
    component: () => import("@/views/wechat/step3/wechatStep3"),
  },
  {
    path: "/alltype/wechat/step3/step4", // 和朋友语音视频通话
    name: "wechatStep34",
    component: () => import("@/views/wechat/step3/wechatStep4"),
  },
  {
    path: "/alltype/wechat/step3/step5", // 和朋友语音视频通话
    name: "wechatStep35",
    component: () => import("@/views/wechat/step3/wechatStep5"),
  },
  {
    path: "/alltype/wechat/step3/step6", // 和朋友语音视频通话
    name: "wechatStep36",
    component: () => import("@/views/wechat/step3/wechatStep6"),
  },
  {
    path: "/alltype/wechat/step3/step7", // 和朋友语音视频通话
    name: "wechatStep37",
    component: () => import("@/views/wechat/step3/wechatStep7"),
  },
  {
    path: "/alltype/wechat/step3/step8", // 和朋友语音视频通话
    name: "wechatStep38",
    component: () => import("@/views/wechat/step3/wechatStep8"),
  },
  {
    path: "/alltype/wechat/step4", // 发送图片和视频
    name: "step4Index",
    component: () => import("@/views/wechat/step4Index"),
  },
  {
    path: "/alltype/wechat/step4/step1", // 发送图片和视频
    name: "wechatStep41",
    component: () => import("@/views/wechat/step4/wechatStep1"),
  },
  {
    path: "/alltype/wechat/step4/step2", // 发送图片和视频
    name: "wechatStep42",
    component: () => import("@/views/wechat/step4/wechatStep2"),
  },
  {
    path: "/alltype/wechat/step4/step3", // 发送图片和视频
    name: "wechatStep43",
    component: () => import("@/views/wechat/step4/wechatStep3"),
  },
  {
    path: "/alltype/wechat/step4/step4", // 发送图片和视频
    name: "wechatStep44",
    component: () => import("@/views/wechat/step4/wechatStep4"),
  },
  {
    path: "/alltype/wechat/step4/step5", // 发送图片和视频
    name: "wechatStep45",
    component: () => import("@/views/wechat/step4/wechatStep5"),
  },
  {
    path: "/alltype/wechat/step4/step6", // 发送图片和视频
    name: "wechatStep46",
    component: () => import("@/views/wechat/step4/wechatStep6"),
  },
  {
    path: "/alltype/wechat/step5", // 如何发送地理位置
    name: "step5Index",
    component: () => import("@/views/wechat/step5Index"),
  },
  {
    path: "/alltype/wechat/step5/step1", // 如何发送地理位置
    name: "wechatStep51",
    component: () => import("@/views/wechat/step5/wechatStep1"),
  },
  {
    path: "/alltype/wechat/step5/step2", // 如何发送地理位置
    name: "wechatStep52",
    component: () => import("@/views/wechat/step5/wechatStep2"),
  },
  {
    path: "/alltype/wechat/step5/step3", // 如何发送地理位置
    name: "wechatStep53",
    component: () => import("@/views/wechat/step5/wechatStep3"),
  },
  {
    path: "/alltype/wechat/step5/step4", // 如何发送地理位置
    name: "wechatStep54",
    component: () => import("@/views/wechat/step5/wechatStep4"),
  },
  {
    path: "/alltype/wechat/step5/step5", // 如何发送地理位置
    name: "wechatStep55",
    component: () => import("@/views/wechat/step5/wechatStep5"),
  },
  {
    path: "/alltype/alipay", // 支付宝入口
    name: "alipayIndex",
    component: () => import("@/views/alipay/alipayIndex"),
  },
  {
    path: "/alltype/alipay/step1", // 支付宝实名认证
    name: "alipayStep1Index",
    component: () => import("@/views/alipay/step1Index"),
  },
  {
    path: "/alltype/alipay/step1/step1", // 支付宝实名认证
    name: "alipayStep11",
    component: () => import("@/views/alipay/step1/alipayStep1"),
  },
  {
    path: "/alltype/alipay/step1/step2", // 支付宝实名认证
    name: "alipayStep12",
    component: () => import("@/views/alipay/step1/alipayStep2"),
  },
  {
    path: "/alltype/alipay/step1/step3", // 支付宝实名认证
    name: "alipayStep13",
    component: () => import("@/views/alipay/step1/alipayStep3"),
  },
  {
    path: "/alltype/alipay/step1/step4", // 支付宝实名认证
    name: "alipayStep14",
    component: () => import("@/views/alipay/step1/alipayStep4"),
  },
  {
    path: "/alltype/alipay/step1/step5", // 支付宝实名认证
    name: "alipayStep15",
    component: () => import("@/views/alipay/step1/alipayStep5"),
  },
  {
    path: "/alltype/alipay/step1/step6", // 支付宝实名认证
    name: "alipayStep16",
    component: () => import("@/views/alipay/step1/alipayStep6"),
  },
  {
    path: "/alltype/alipay/step1/step7", // 支付宝实名认证
    name: "alipayStep17",
    component: () => import("@/views/alipay/step1/alipayStep7"),
  },
  {
    path: "/alltype/alipay/step1/step8", // 支付宝实名认证
    name: "alipayStep18",
    component: () => import("@/views/alipay/step1/alipayStep8"),
  },
  {
    path: "/alltype/alipay/step1/step9", // 支付宝实名认证
    name: "alipayStep19",
    component: () => import("@/views/alipay/step1/alipayStep9"),
  },
  {
    path: "/alltype/alipay/step1/step10", // 支付宝实名认证
    name: "alipayStep110",
    component: () => import("@/views/alipay/step1/alipayStep10"),
  },
  {
    path: "/alltype/alipay/step2", // 支付宝绑定银行卡
    name: "alipayStep2Index",
    component: () => import("@/views/alipay/step2Index"),
  },
  {
    path: "/alltype/alipay/step2/step1", // 支付宝绑定银行卡
    name: "alipayStep21",
    component: () => import("@/views/alipay/step2/alipayStep1"),
  },
  {
    path: "/alltype/alipay/step2/step2", // 支付宝绑定银行卡
    name: "alipayStep22",
    component: () => import("@/views/alipay/step2/alipayStep2"),
  },
  {
    path: "/alltype/alipay/step2/step3", // 支付宝绑定银行卡
    name: "alipayStep23",
    component: () => import("@/views/alipay/step2/alipayStep3"),
  },
  {
    path: "/alltype/alipay/step2/step4", // 支付宝绑定银行卡
    name: "alipayStep24",
    component: () => import("@/views/alipay/step2/alipayStep4"),
  },
  {
    path: "/alltype/alipay/step2/step5", // 支付宝绑定银行卡
    name: "alipayStep25",
    component: () => import("@/views/alipay/step2/alipayStep5"),
  },
  {
    path: "/alltype/alipay/step2/step6", // 支付宝绑定银行卡
    name: "alipayStep26",
    component: () => import("@/views/alipay/step2/alipayStep6"),
  },
  {
    path: "/alltype/alipay/step2/step7", // 支付宝绑定银行卡
    name: "alipayStep27",
    component: () => import("@/views/alipay/step2/alipayStep7"),
  },
  {
    path: "/alltype/alipay/step2/step8", // 支付宝绑定银行卡
    name: "alipayStep28",
    component: () => import("@/views/alipay/step2/alipayStep8"),
  },
  {
    path: "/alltype/alipay/step2/step9", // 支付宝绑定银行卡
    name: "alipayStep29",
    component: () => import("@/views/alipay/step2/alipayStep9"),
  },
  {
    path: "/alltype/alipay/step2/step10", // 支付宝绑定银行卡
    name: "alipayStep210",
    component: () => import("@/views/alipay/step2/alipayStep10"),
  },
  {
    path: "/alltype/alipay/step2/step11", // 支付宝绑定银行卡
    name: "alipayStep211",
    component: () => import("@/views/alipay/step2/alipayStep11"),
  },
  {
    path: "/alltype/alipay/step3", // 支付宝付钱
    name: "alipayStep3Index",
    component: () => import("@/views/alipay/step3Index"),
  },
  {
    path: "/alltype/alipay/step3/step1", // 支付宝付钱
    name: "alipayStep31",
    component: () => import("@/views/alipay/step3/alipayStep1"),
  },
  {
    path: "/alltype/alipay/step3/step2", // 支付宝付钱
    name: "alipayStep32",
    component: () => import("@/views/alipay/step3/alipayStep2"),
  },
  {
    path: "/alltype/alipay/step3/step3", // 支付宝付钱
    name: "alipayStep33",
    component: () => import("@/views/alipay/step3/alipayStep3"),
  },
  {
    path: "/alltype/alipay/step3/step4", // 支付宝付钱
    name: "alipayStep34",
    component: () => import("@/views/alipay/step3/alipayStep4"),
  },
  {
    path: "/alltype/alipay/step3/step5", // 支付宝付钱
    name: "alipayStep35",
    component: () => import("@/views/alipay/step3/alipayStep5"),
  },
  {
    path: "/alltype/alipay/step3/step6", // 支付宝付钱
    name: "alipayStep36",
    component: () => import("@/views/alipay/step3/alipayStep6"),
  },
  {
    path: "/alltype/alipay/step3/step7", // 支付宝付钱
    name: "alipayStep37",
    component: () => import("@/views/alipay/step3/alipayStep7"),
  },
  {
    path: "/alltype/alipay/step3/step8", // 支付宝付钱
    name: "alipayStep38",
    component: () => import("@/views/alipay/step3/alipayStep8"),
  },
  {
    path: "/alltype/alipay/step3/step9", // 支付宝付钱
    name: "alipayStep39",
    component: () => import("@/views/alipay/step3/alipayStep9"),
  },
  {
    path: "/alltype/alipay/step4", // 支付宝安全设置
    name: "alipayStep4Index",
    component: () => import("@/views/alipay/step4Index"),
  },
  {
    path: "/alltype/alipay/step4/step1", // 支付宝安全设置
    name: "alipayStep41",
    component: () => import("@/views/alipay/step4/alipayStep1"),
  },
  {
    path: "/alltype/alipay/step4/step2", // 支付宝安全设置
    name: "alipayStep42",
    component: () => import("@/views/alipay/step4/alipayStep2"),
  },
  {
    path: "/alltype/alipay/step4/step3", // 支付宝安全设置
    name: "alipayStep43",
    component: () => import("@/views/alipay/step4/alipayStep3"),
  },
  {
    path: "/alltype/alipay/step4/step4", // 支付宝安全设置
    name: "alipayStep44",
    component: () => import("@/views/alipay/step4/alipayStep4"),
  },
  {
    path: "/alltype/alipay/step4/step5", // 支付宝安全设置
    name: "alipayStep45",
    component: () => import("@/views/alipay/step4/alipayStep5"),
  },
  {
    path: "/alltype/alipay/step4/step6", // 支付宝安全设置
    name: "alipayStep46",
    component: () => import("@/views/alipay/step4/alipayStep6"),
  },
  {
    path: "/alltype/alipay/step4/step7", // 支付宝安全设置
    name: "alipayStep47",
    component: () => import("@/views/alipay/step4/alipayStep7"),
  },
  {
    path: "/alltype/alipay/step4/step8", // 支付宝安全设置
    name: "alipayStep48",
    component: () => import("@/views/alipay/step4/alipayStep8"),
  },
  {
    path: "/alltype/alipay/step4/step9", // 支付宝安全设置
    name: "alipayStep49",
    component: () => import("@/views/alipay/step4/alipayStep9"),
  },
  {
    path: "/alltype/alipay/step4/step10", // 支付宝安全设置
    name: "alipayStep410",
    component: () => import("@/views/alipay/step4/alipayStep10"),
  },
  {
    path: "/alltype/alipay/step4/step11", // 支付宝安全设置
    name: "alipayStep411",
    component: () => import("@/views/alipay/step4/alipayStep11"),
  },
  {
    path: "/alltype/alipay/step4/step12", // 支付宝安全设置
    name: "alipayStep412",
    component: () => import("@/views/alipay/step4/alipayStep12"),
  },
  {
    path: "/alltype/alipay/step4/step13", // 支付宝安全设置
    name: "alipayStep413",
    component: () => import("@/views/alipay/step4/alipayStep13"),
  },
  {
    path: "/alltype/alipay/step4/step14", // 支付宝安全设置
    name: "alipayStep414",
    component: () => import("@/views/alipay/step4/alipayStep14"),
  },
  {
    path: "/alltype/alipay/step4/step15", // 支付宝安全设置
    name: "alipayStep415",
    component: () => import("@/views/alipay/step4/alipayStep15"),
  },
  {
    path: "/alltype/zlb", // 浙里办
    name: "zlbIndex",
    component: () => import("@/views/zlb/zlbIndex"),
  },
  {
    path: "/alltype/zlb/step1", // 浙里办注册
    name: "zlbStep1Index",
    component: () => import("@/views/zlb/step1Index"),
  },
  {
    path: "/alltype/zlb/step1/step1", // 浙里办注册
    name: "zlbStep11",
    component: () => import("@/views/zlb/step1/zlbStep1"),
  },
  {
    path: "/alltype/zlb/step1/step2", // 浙里办注册
    name: "zlbStep12",
    component: () => import("@/views/zlb/step1/zlbStep2"),
  },
  {
    path: "/alltype/zlb/step1/step3", // 浙里办注册
    name: "zlbStep13",
    component: () => import("@/views/zlb/step1/zlbStep3"),
  },
  {
    path: "/alltype/zlb/step1/step4", // 浙里办注册
    name: "zlbStep14",
    component: () => import("@/views/zlb/step1/zlbStep4"),
  },
  {
    path: "/alltype/zlb/step1/step5", // 浙里办注册
    name: "zlbStep15",
    component: () => import("@/views/zlb/step1/zlbStep5"),
  },
  {
    path: "/alltype/zlb/step1/step6", // 浙里办注册
    name: "zlbStep16",
    component: () => import("@/views/zlb/step1/zlbStep6"),
  },
  {
    path: "/alltype/zlb/step1/step7", // 浙里办注册
    name: "zlbStep17",
    component: () => import("@/views/zlb/step1/zlbStep7"),
  },
  {
    path: "/alltype/zlb/step1/step8", // 浙里办注册
    name: "zlbStep18",
    component: () => import("@/views/zlb/step1/zlbStep8"),
  },
  {
    path: "/alltype/zlb/step1/step9", // 浙里办注册
    name: "zlbStep19",
    component: () => import("@/views/zlb/step1/zlbStep9"),
  },
  {
    path: "/alltype/zlb/step1/step10", // 浙里办注册
    name: "zlbStep110",
    component: () => import("@/views/zlb/step1/zlbStep10"),
  },
  {
    path: "/alltype/zlb/step2", // 浙里办实名认证
    name: "zlbStep2Index",
    component: () => import("@/views/zlb/step2Index"),
  },
  {
    path: "/alltype/zlb/step2/step1", // 浙里办实名认证
    name: "zlbStep21",
    component: () => import("@/views/zlb/step2/zlbStep1"),
  },
  {
    path: "/alltype/zlb/step2/step2", // 浙里办实名认证
    name: "zlbStep22",
    component: () => import("@/views/zlb/step2/zlbStep2"),
  },
  {
    path: "/alltype/zlb/step2/step3", // 浙里办实名认证
    name: "zlbStep23",
    component: () => import("@/views/zlb/step2/zlbStep3"),
  },
  {
    path: "/alltype/zlb/step2/step4", // 浙里办实名认证
    name: "zlbStep24",
    component: () => import("@/views/zlb/step2/zlbStep4"),
  },
  {
    path: "/alltype/zlb/step2/step5", // 浙里办实名认证
    name: "zlbStep25",
    component: () => import("@/views/zlb/step2/zlbStep5"),
  },
  {
    path: "/alltype/zlb/step2/step6", // 浙里办实名认证
    name: "zlbStep26",
    component: () => import("@/views/zlb/step2/zlbStep6"),
  },
  {
    path: "/alltype/zlb/step2/step7", // 浙里办实名认证
    name: "zlbStep27",
    component: () => import("@/views/zlb/step2/zlbStep7"),
  },
  {
    path: "/alltype/zlb/step3", // 浙里办打开潮学通
    name: "zlbStep3Index",
    component: () => import("@/views/zlb/step3Index"),
  },
  {
    path: "/alltype/zlb/step3/step1", // 浙里办打开潮学通
    name: "zlbStep31",
    component: () => import("@/views/zlb/step3/zlbStep1"),
  },
  {
    path: "/alltype/zlb/step3/step2", // 浙里办打开潮学通
    name: "zlbStep32",
    component: () => import("@/views/zlb/step3/zlbStep2"),
  },
  {
    path: "/alltype/zlb/step3/step3", // 浙里办打开潮学通
    name: "zlbStep33",
    component: () => import("@/views/zlb/step3/zlbStep3"),
  },
  {
    path: "/alltype/zlb/step3/step4", // 浙里办打开潮学通
    name: "zlbStep34",
    component: () => import("@/views/zlb/step3/zlbStep4"),
  },
  {
    path: "/alltype/zlb/step3/step5", // 浙里办打开潮学通
    name: "zlbStep35",
    component: () => import("@/views/zlb/step3/zlbStep5"),
  },
  {
    path: "/alltype/zlb/step4", // 潮学通培训报名
    name: "zlbStep4Index",
    component: () => import("@/views/zlb/step4Index"),
  },
  {
    path: "/alltype/zlb/step4/step1", // 潮学通培训报名
    name: "zlbStep41",
    component: () => import("@/views/zlb/step4/zlbStep1"),
  },
  {
    path: "/alltype/zlb/step4/step2", // 潮学通培训报名
    name: "zlbStep42",
    component: () => import("@/views/zlb/step4/zlbStep2"),
  },
  {
    path: "/alltype/zlb/step4/step3", // 潮学通培训报名
    name: "zlbStep43",
    component: () => import("@/views/zlb/step4/zlbStep3"),
  },
  {
    path: "/alltype/zlb/step4/step4", // 潮学通培训报名
    name: "zlbStep44",
    component: () => import("@/views/zlb/step4/zlbStep4"),
  },
  {
    path: "/alltype/zlb/step4/step5", // 潮学通培训报名
    name: "zlbStep45",
    component: () => import("@/views/zlb/step4/zlbStep5"),
  },
  {
    path: "/alltype/zlb/step4/step6", // 潮学通培训报名
    name: "zlbStep46",
    component: () => import("@/views/zlb/step4/zlbStep6"),
  },
  {
    path: "/alltype/zlb/step5", // 潮学通培训签到
    name: "zlbStep5Index",
    component: () => import("@/views/zlb/step5Index"),
  },
  {
    path: "/alltype/zlb/step5/step1", // 潮学通培训签到
    name: "zlbStep51",
    component: () => import("@/views/zlb/step5/zlbStep1"),
  },
  {
    path: "/alltype/zlb/step5/step2", // 潮学通培训签到
    name: "zlbStep52",
    component: () => import("@/views/zlb/step5/zlbStep2"),
  },
  {
    path: "/alltype/zlb/step5/step3", // 潮学通培训签到
    name: "zlbStep53",
    component: () => import("@/views/zlb/step5/zlbStep3"),
  },
  {
    path: "/alltype/zlb/step5/step4", // 潮学通培训签到
    name: "zlbStep54",
    component: () => import("@/views/zlb/step5/zlbStep4"),
  },
  {
    path: "/alltype/zlb/step5/step5", // 潮学通培训签到
    name: "zlbStep55",
    component: () => import("@/views/zlb/step5/zlbStep5"),
  },
  {
    path: "/alltype/zlb/step5/step6", // 潮学通培训签到
    name: "zlbStep56",
    component: () => import("@/views/zlb/step5/zlbStep6"),
  },
  {
    path: "/alltype/zlb/step5/step7", // 潮学通培训签到
    name: "zlbStep57",
    component: () => import("@/views/zlb/step5/zlbStep7"),
  },
  {
    path: "/alltype/zlb/step5/step8", // 潮学通培训签到
    name: "zlbStep58",
    component: () => import("@/views/zlb/step5/zlbStep8"),
  },
  {
    path: "/alltype/zlb/step5/step9", // 潮学通培训签到
    name: "zlbStep59",
    component: () => import("@/views/zlb/step5/zlbStep9"),
  },
  {
    path: "/alltype/zlb/step5/step10", // 潮学通培训签到
    name: "zlbStep510",
    component: () => import("@/views/zlb/step5/zlbStep10"),
  },
];

const router = new Router({
  mode: "history",
  routes: routes,
});

export default router;
